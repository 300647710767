<template>
  <b-card title="İş Emri Formu">
    <work-no />
    <work-date />
    <sections />
    <brands />
    <locations />
    <workers />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import WorkDate from '@/views/Service_work_orders/Orders/elements/Wdate.vue'
import WorkNo from '@/views/Service_work_orders/Orders/elements/WorkNo.vue'
import Sections from '@/views/Service_work_orders/Orders/elements/Sections.vue'
import Workers from '@/views/Service_work_orders/Orders/elements/Workers.vue'
import Locations from '@/views/Service_work_orders/Orders/elements/Locations.vue'
import Brands from '@/views/Service_work_orders/Orders/elements/Brands.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    WorkDate,
    WorkNo,
    Sections,
    Workers,
    Locations,
    Brands,
  },
}
</script>
