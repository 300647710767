<template>
  <b-form-group
    label="Lokasyon"
    label-for="id_com_location"
  >
    <validation-provider
      #default="{ errors }"
      name="Lokasyon"
      rules="required"
    >
      <v-select
        id="id_com_location"
        v-model="dataItem.id_com_location"
        label="title"
        :reduce="item => item.id"
        :options="dataList"
        placeholder="Lokasyon"
        @input="getWorkers"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'Locations',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['serviceWorkOrders/dataItem']
    },
    dataList() {
      return this.$store.getters['locations/getLocations']
    },
  },
  created() {
    localize('tr')
    this.getLocations()
  },
  methods: {
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        where: {
          'com_location.id !=': 4,
        },
      })
    },
    getWorkers() {
      if (this.dataItem.id_com_location) {
        this.dataItem.id_com_service_workers = null
        this.$store.dispatch('swoWorkers/getDataList', {
          select: [
            'com_service_workers.id AS id',
            'com_service_workers.fullname AS title',
            'com_service_workers.subtitle AS subtitle',
            'com_service_sections.title AS section',
            'com_location.title AS location',
          ],
          where: {
            'com_service_workers.id_com_location': this.dataItem.id_com_location,
          },
        })
      }
    },
  },
}
</script>
